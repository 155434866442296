import React from 'react'

const FormField = props => {
  const errRegex = new RegExp(props.name) // ie. 'email' or 'password'

  const hasError = props.error && props.error.code.match(errRegex)

  return (
    <label className={hasError ? 'is-invalid-label' : null}>
      {props.label}
      <input
        type={props.type}
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        placeholder={props.placeholder}
        className={hasError ? 'is-invalid-input' : null}
      />
      <small className={`form-error ${hasError ? 'is-visible' : null}`}>
        {hasError ? props.error.message : null}
      </small>
    </label>
  )
}

export default FormField
