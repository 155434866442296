import React from 'react'

import Layout, { SessionContext } from '../components/layout'
// import Firebase from '../utilities/Firebase'
import FormField from '../components/FormField'
import { navigate } from 'gatsby'

class Login extends React.Component {
  static contextType = SessionContext

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      error: null,
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleInputChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  async handleSubmit(event) {
    event.preventDefault()
    // clear the error then log them in
    this.setState({ error: null }, () => {
      this.context.Firebase.auth
        .signInWithEmailAndPassword(this.state.email, this.state.password)
        .then(user => {
          navigate('/')
        })
        .catch(e => {
          this.setState({ error: e })
        })
    })
  }

  render() {
    return (
      <Layout requireAuth={false}>
        <div className="grid-container">
          <div className="grid-x grid-margin-x align-center">
            <div className="cell large-4 medium-6">
              {this.state.error ? (
                <div className="alert callout">
                  <p>{this.state.error.message}</p>
                </div>
              ) : null}
              <h3>Log in</h3>
              <form onSubmit={this.handleSubmit}>
                <FormField
                  label="Email"
                  name="email"
                  type="text"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  placeholder="jane@example.com"
                  error={this.state.error}
                />
                <FormField
                  label="Password"
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.handleInputChange}
                  error={this.state.error}
                />
                <button className="button" type="submit">
                  Log In
                </button>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Login
